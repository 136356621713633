import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from '../components/Misc/Layouts';
import AnimationRevealPage from '../helpers/AnimationRevealPage';
import Header from '../components/Layout/Header';
import Footer from '../components/Layout/Footer';
import { SectionHeading } from '../components/Misc/Headings';
import Helmet from '../components/Layout/Helmet';

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  padding: 2rem 2rem 0 2rem;
`;

export default ({ headingText = 'Privacy Policy' }) => (
    <>
        <Helmet title="Lunaar - Privacy Policy" desc="Privacy Policy of Lunaar Application." meta="Privacy Policy of Lunaar Application." />
        <AnimationRevealPage disabled>
            <Wrapper>
                <Header />
                <Container>
                    <ContentWithPaddingXl>
                        <HeadingRow>
                            <Heading>{headingText}</Heading>
                        </HeadingRow>
                        <Text>

                            <p>Last updated: February 2021</p>

                            <p>By using Lunaar (“Service”), and any related Services of Griffoul Industries (“Company”), you are agreeing to our following GDPR compliant privacy policy.</p>

                            <p>
                                For the Company, protecting your personal data is a priority. We comply with all relevant data protection legislation and the following privacy
                                notice is intended to provide transparency about how we handle your personal data.
                            </p>

                            <h1>Interpretation and Definitions</h1>
                            <h2>Interpretation</h2>
                            <p>
                                The words of which the initial letter is capitalized have meanings defined under the following conditions.
                            </p>
                            <p>
                                The following definitions shall have the same meaning regardless of whether they appear in singular or in
                                plural.
                            </p>

                            <h2>Definitions</h2>
                            <p>For the purposes of these Terms and Conditions:</p>
                            <ul>
                                <li>
                                    <strong>Affiliate</strong>
                                    {' '}
                                    means an entity that controls, is controlled by or is under common control
                                    with a party, where "control" means ownership of 50% or more of the shares, equity interest or other
                                    securities entitled to vote for election of directors or other managing authority.
                                </li>
                                <li>
                                    <strong>Company</strong>
                                    {' '}
                                    (referred to as either "the Company", "We", "Us" or "Our" in this Agreement)
                                    refers to Griffoul Industries (identification information at the end of the page).
                                </li>
                                <li>
                                    <strong>Country</strong>
                                    {' '}
                                    refers to: France
                                </li>
                                <li>
                                    <strong>Service</strong>
                                    {' '}
                                    (also referred as "Product" in this Agreement)
                                    refers to the Lunaar platform (
                                    <a href="https://center.lunaar.app">https://center.lunaar.app</a>
                                    ).
                                </li>
                                <li>
                                    <strong>Privacy Policy</strong>
                                    {' '}
                                    (also referred as "Privacy", "Privacy Policies", "GDPR compliant Privacy Policy") mean this Privacy Policy that
                                    form the entire agreement between You and the Company regarding the use of personal information by the Service.
                                </li>
                                <li>
                                    <strong>Third-party Service</strong>
                                    {' '}
                                    means any services or content (including data,
                                    information, products or services) provided by a third-party that may be displayed, included or made
                                    available by the Service.
                                </li>
                                <li>
                                    <strong>Website</strong>
                                    {' '}
                                    refers to Lunaar showcase pages, accessible from
                                    {' '}
                                    <a href="https://www.lunaar.app">https://www.lunaar.app</a>
                                </li>
                                <li>
                                    <strong>You</strong>
                                    {' '}
                                    (referred to as either "Your" in this Agreement)
                                    means the individual accessing or using the Service, or the Company, or other legal
                                    entity on behalf of which such individual is accessing or using the Service, as applicable.
                                </li>
                            </ul>

                            <h1>Personal Data and the Provision of Services</h1>
                            <p>
                                When you subscribe to one of Our Services, we only ask for information that is essential to deliver our
                                Products and Services to you or for invoicing reasons. In most cases, this is limited to name, address,
                                contact data, and payment details.
                            </p>

                            <h1>Contact and Invoicing Data</h1>
                            <p>
                                <strong>Sign Up</strong>
                                <br />
                                Upon subscribing to our products, we ask for data we need to provide the services.
                                To become user of the Service, we just ask for a mail address. You are allowed to sign up with some
                                social credentials or with a universal form. This is possible through ISO27001 certified third party
                                Auth0. It means that your data is stored securely but not on our systems: your social network keeps
                                control of authentication and we just ask permission to read your profile to get your mail address.
                                This is why updating user information is not possible in the Service.
                            </p>
                            <p>
                                <strong>Billing</strong>
                                <br />
                                In order to provide you with transparent billing, it is necessary to temporarily store certain invoicing data.
                                By subscribing to the Service, we ask you minimal but mandatory information for the invoicing process.
                                This information is stored in order to provide you a new invoice for each billing cycle.
                                Here too We are using a third party service called Stripe to manage securely all the billing process.
                            </p>

                            <h1>Usage Data</h1>
                            <p>
                                We aim to provide quality and reliability but on occasion, issues may arise. In order to react quickly,
                                it may be necessary to temporarily record usage and traffic data to provide to our Support staff.
                                We do this to ensure we fulfill our obligations to you and to design products and services aligned to your needs.
                            </p>

                            <h1>Usage of Your Data</h1>
                            <p>
                                <strong>The Usage of Your Personal Data</strong>
                                <br />
                                Some of your personal data we need for processing your subscription and for provided customer-focused services.
                            </p>
                            <p>
                                <strong>Data Processing upon receiving Your Subscription Request</strong>
                                <br />
                                Our commitment to you is to provide value for money top products and services to our customers. In order
                                to ensure smooth and trouble-free order processes, your request is thoroughly vetted and invest in fraud
                                prevention before confirming your subscription.
                            </p>
                            <p>
                                <strong>Invoice and Customer Information</strong>
                                <br />
                                You will receive the subscription confirmation and information by email. We use the email address you have
                                provided to order the product. You will also receive your invoices and helpful information in the same manner.
                            </p>
                            <p>
                                <strong>Usage Data as part of Our Services</strong>
                                <br />
                                Certain data is recorded during the use of our products and services to enable us to identify issues with our
                                products and to continuously improve our products and services. We implement a range of technological and
                                organizational measures to protect your privacy and ensure the safety of your personal data.
                            </p>
                            <p>
                                <strong>Personal Data managed by You</strong>
                                <br />
                                According to our Terms and Conditions You are responsible for any applicable legal requirements in
                                respect of Your content. Therefore, any article, video, or other content collected, added, or otherwise
                                made available by You onto our Service, whether published content or not, is not subject to our Privacy Notice.
                            </p>
                            <p>
                                <strong>Improving our products and services</strong>
                                <br />
                                We have a legitimate interest to analyze the data we collect to improve our products and services. We use a
                                variety of methods and tools to do this. As described in this policy, we collect data that relates to you;
                                your usage of our products and services; and your interactions with us.
                                We also collect public data and data from third parties to better understand our customers‘ needs. We protect
                                your privacy through a range of technical and organizational measures tailored to each situation and respect
                                your choices about how we use your data.
                            </p>

                            <h1>Transferring Data to Third-party Services</h1>
                            <p>
                                <strong>External Partner Companies</strong>
                                <br />
                                As we work with selected partners to offer you safe services and sometimes act as an intermediary for our
                                partners, it is necessary to transfer certain personal data to third parties, for example, authentication
                                (Auth0) and billing (Stripe) processes.
                            </p>

                            <h1>Cookies Usage</h1>
                            <p>
                                No cookies used by the Service.
                            </p>

                            <h1>Transparency Is Our Aim</h1>
                            <p>
                                All data we receive from You during the lifetime of Your service usage is used primarily to provide the
                                level of service you would expect. We carry out statistical analyses to improve products and services.
                                These analyses are conducted in compliance with data protection legislation and our internal privacy
                                policies and processes.
                            </p>

                            <h1>Your Rights</h1>
                            <p>
                                In respect of Art. 15 GDPR you have the right to obtain information as to whether or not your personal data
                                are being processed. You also have the right to have your personal data rectified if it is inaccurate in
                                accordance with Art. 16 GDPR, a right to be forgotten (Art. 17 GDPR), a right to obtain restriction of
                                processing under given circumstances (Art. 18 GDPR) and the right to data portability stated in Art. 20 GDPR.
                                You have the right to object to the processing of your personal data, as to where the processing is
                                based on Art. 6(1) lit f GDPR.
                            </p>
                            <p>
                                If you wish to exercise one of these rights, please email: julien.griffoul@griffoul.in.
                            </p>
                            <p>
                                You have the right to file a complaint with any supervisory authority.
                            </p>
                        </Text>
                    </ContentWithPaddingXl>
                </Container>
            </Wrapper>
            <Footer />
        </AnimationRevealPage>
    </>
);
